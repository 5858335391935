import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Accordion from "../components/accordion"

const Contraception = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Contraception et Grossesse" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Contraception et Grossesse
        </Typography>
        <Accordion
          initialList={[]}
          entries={[
            {
              id: "contraception",
              header: "Contraception",
              element: (
                <Typography variant="body1">
                  En cas de chirurgie malabsorptive (by-pass gastrique,
                  dérivation biliopancréatique), il faut éviter la contraception
                  orale : <strong>le DIU et l’implant</strong> sont à
                  privilégier.
                </Typography>
              ),
            },
            {
              id: "grossesse",
              header: "Grossesse",
              element: (
                <div>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    Il est recommandé d'attendre 12 à 18 mois (stabilisation du poids) afin d'éviter les carences
                    nutritionnelles et permettre une perte de poids suffisante pour limiter les risques de complications liés
                    à l'excès de poids. Une contraception efficace est donc nécessaire durant cette période.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    La grossesse doit être <strong>programmée</strong>, et <strong>coordonnée par un obstétricien</strong>. Les bilans de carence
                    doivent être réguliers tout au long de la grossesse.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px", textDecoration: 'underline' }}
                  >
                    En cas d'absence d'anticipation de la grossesse, repères lors de la première consultation dans l'attente du suivi spécialisé :
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    Rappel des signes d'alerte (lien) nécessitant une consultation en urgence.<strong> En cas de vomissements
                    répétés, informer la patiente qu'une prise en charge aux urgences gynécologiques est
                    indispensable pour supplémentation et dosage en vitamine B1.</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    Une échographie supplémentaire est habituellement réalisée à 37 SA afin de surveiller la croissance fœtale (risque PAG).
                    En présence d'un anneau gastrique, informer la patiente qu'un ajustement peut être nécessaire durant la grossesse.
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    Bilan biologique :
                    <ul>
                      <li>
                        Bilan systématique habituel (sérologies rubéole et toxoplasmose, VDRL-TPHA, groupe rhésus RAI,
                        albuminurie et glycosurie +/- sérologies VHB, VHC, VIH 1 et 2, dépistage combiné T21)
                      </li>
                      <li>
                        NFS plaquettes, ferritine, coefficient saturation de la transferrine, albumine et préalbumine, vitamine D, PTH, calcémie, phosphorémie,
                        vitamines B1**, B9, B12 et A, temps de prothrombine, magnésium, sélénium** et zinc sérique +/- vitamines K**, B6, C**, E et PP**
                      </li>
                      <li>
                        Dépistage du diabète gestationnel par glycémie à jeun (seuil normal &lt; 0.92 g/L)
                      </li>
                    </ul>
                    <i>** dosages non remboursés par la sécurité sociale</i>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: "block", marginBottom: "16px" }}
                  >
                    Prescrire systématiquement :
                    <ul>
                      <li>
                        Des multivitamines en prise quotidienne (au moins 10 mg de zinc et 1 mg de cuivre, et ne contenant
                        pas plus de 5000 UI de vitamine A), qui seront à poursuivre tout au long de la grossesse
                      </li>
                      <li>
                        De l'acide folique (0.4 mg/jour) jusqu'à 12 SA
                      </li>
                    </ul>
                    <span>
                      Les autres supplémentations sont à adapter en fonction de la chirurgie, du bilan biologique ou de la clinique.
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="inherit"
                    style={{ 
                      marginTop: 20,
                      marginBottom: 20,
                      display: "block",
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <a 
                      target="_blank"
                      href="http://www.cngof.fr/pratiques-cliniques/recommandations-pour-la-pratique-clinique/apercu?path=RPC%2BD%2BORIGINES%2BDIVERSES%252FGroupe%2BBARIA-MAT%252FBARIA-MAT-Synthese%2BReco.pdf&amp;i=37087">
                      http://www.cngof.fr/pratiques-cliniques/recommandations-pour-la-pratique-clinique
                    </a>
                  </Typography>
                </div>
              ),
            },
          ]}
        />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Contraception
